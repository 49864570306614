import ReactDOM from "react-dom";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

const e = React.createElement;

function DropZone({ styleClass, input, isMultiple, iconSrc }) {
	const [fileList, setFileList] = useState([]);

	const onDrop = useCallback((acceptedFiles) => {
		const dataTransfer = new DataTransfer();
		// FileLists are readonly -> need to create a new one
		if (isMultiple) {
			acceptedFiles.forEach((file) => {
				dataTransfer.items.add(file);
			});
			[...inputRef.current.files].forEach((file) => {
				dataTransfer.items.add(file);
			});
		} else {
			dataTransfer.items.add(acceptedFiles[0]);
		}
		inputRef.current.files = dataTransfer.files;
		setFileList([...inputRef.current.files]);
	}, []);

	const removeFile = (file) => {
		const tempDataTransfer = new DataTransfer();
		[...inputRef.current.files].forEach((f) => {
			if (f["name"] !== file.name) {
				tempDataTransfer.items.add(f);
			}
		});
		inputRef.current.files = tempDataTransfer.files;
		setFileList([...inputRef.current.files]);
	};

	const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
		onDrop,
	});

	return (
		<>
			<div {...getRootProps()} className={`${styleClass}__area` + (isDragActive ? " is-dragover" : "")}>
				<input
					ref={inputRef}
					{...getInputProps({
						multiple: input.multiple,
						name: input.name,
						id: input.id,
					})}
				/>
				<img src={iconSrc} className={`${styleClass}__icon`} />
				<div className={`${styleClass}__instructions`}>
					Drag files here or <span className={`${styleClass}__browse`}>browse</span>
				</div>
			</div>
			<div className={`${styleClass}__controls`}>
				{fileList.map((file, i) => {
					return (
						<span key={`${file.name}-${i}`} className={`${styleClass}__filename`}>
							{file.name}
							<svg viewBox="0 0 25 25">
								<use xlinkHref="#icon-tick"></use>
							</svg>
							<button
								className={`${styleClass}__remove`}
								onClick={(e) => {
									e.preventDefault();
									removeFile(file);
								}}
							>
								Remove
							</button>
						</span>
					);
				})}
			</div>
		</>
	);
}

class DragDrop extends HTMLElement {
	connectedCallback() {
		const input = this.querySelector("input");
		const multiple = input.getAttribute("multiple") != null;
		const styleClass = this.getAttribute("style-class");
		const iconSrc = this.getAttribute("icon");
		this.classList.add(styleClass);

		ReactDOM.render(<DropZone styleClass={styleClass} input={input} isMultiple={multiple} iconSrc={iconSrc} />, this);
	}
}

customElements.define("drag-drop", DragDrop);
